import { useStaticQuery, graphql } from "gatsby"

export const getHeroImage = () => {
  const { contentfulAsset } = useStaticQuery(
	graphql`
	  query getHeroImage {
		contentfulAsset(file: {fileName: {eq: "youview-support-background.jpg"}}) {
			 fixed(width: 1800, quality: 100) {
			  base64
			  aspectRatio
			  width
			  height
			  src
			  srcSet
			  srcWebp
			  srcSetWebp
			}
			fluid(maxWidth: 1800, quality: 100) {
			  aspectRatio
			  sizes
			  src
			  srcSet
			  srcSetWebp
			  srcWebp
			}
		  }
	  }
	`
  )
  // console.log(heroImage.contentfulAsset);
  return contentfulAsset
}