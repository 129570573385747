import React from 'react'
import { getHeroImage } from "../hooks/get-hero-image"
import Img from 'gatsby-image'


//import styles from './alert.module.scss'


export default function heroImage() {
	const { fixed, fluid } = getHeroImage()
	
	if(fixed && fixed != '') {
	return <Img backgroundColor={`#0a3963`} fluid={fluid} Tag={'figure'} alt={` `}  loading={'eager'} role={"decoration"} critical={true}/>

	}
}