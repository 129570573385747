import React from 'react'
import { Link } from 'gatsby'

import styles from './footer.module.scss'


export default () => (
<div className={styles.youviewFooterLogos}>
 <div className={styles.youviewFooterPartners}>
      <div className={styles.partnersLogos}>
      <div className={styles.inPartnershipWith}>In Partnership with:</div>
      
      
        <ul className={styles.footerLogos} >
          <li>
            <a id="tracking-partnership-bbc" target="_blank" className={styles.bbc} href="http://www.bbc.co.uk/" title="Visit the BBC homepage"><figure className={styles.bbc}></figure></a>
        </li>
        <li>
            <a id="tracking-partnership-itv" target="_blank" className={styles.itv} href="http://www.itv.com/" title="Visit the ITV homepage"><figure className={styles.itv}></figure></a>
        </li>
        <li>
            <a id="tracking-partnership-channel4" target="_blank" className={styles.channel4} href="http://channel4.com/" title="Visit the Channel 4 homepage"><figure className={styles.channel4}></figure></a>
        </li>
        <li>
            <a id="tracking-partnership-channel5" target="_blank" className={styles.channel5} href="http://www.channel5.com/" title="Visit the Channel 5 homepage"><figure className={styles.channel5}></figure></a>
        </li>
        <li>
            <a id="tracking-partnership-bt" target="_blank" className={styles.bt} href="http://www.bt.com/" title="Visit the BT homepage"><figure className={styles.bt}></figure></a>
        </li>
        <li>
            <a id="tracking-partnership-talktalk" target="_blank" className={styles.talktalk} href="http://www.talktalk.co.uk/" title="Visit the TalkTalk homepage"><figure className={styles.talktalk} ></figure></a>
        </li>


        </ul>
      </div>
    
     

  <div className={styles.youviewFooterLogo}>
    <div className={styles.logo}><span>YouView</span></div> 
  </div>
</div>
</div>
)
