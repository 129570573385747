import React from 'react'
import { Link } from 'gatsby'
import FooterLegal from './footer-legal'
import FooterLogos from './footer-logos'
//import Footnotes from './footnotes'
import FooterNavigation from './footer-navigation'

import styles from './footer.module.scss'

export default class Footer extends React.Component {
  render() {
    return <footer role={"contentinfo"} className={styles.flex}>
   
    <FooterNavigation />
     <FooterLogos />
     <FooterLegal currentYear={this.props.currentYear}/>
   </footer>
  }
}
