import React from 'react'
import { Link } from 'gatsby'

import styles from './navigation.module.scss'


class CookieBanner extends React.Component {
     componentDidMount() {



    }

  render() {
    var hideStyle = {display: 'none'};
    return (
         <div id="cookie-disclaimer-container" className={[styles.cookieContainer, "container"].join(' ')}  style={hideStyle}>
          <nav className={[styles.navbarFixedTop, "navbar-fixed-top"].join(' ')}>
            <div className={"container"}>
              <div className={"navbar-inner navbar-content-center"} id="cookie_accept">
                <a className={[styles.closeLink, "pull-right"].join(' ')}>
                   <i className={"icon-x icon-white"}></i>
                </a><p className={"credit"}>This site uses cookies. By continuing to browse, you are agreeing to our use of cookies. <a href="https://www.youview.com/policies/">Read our cookie policy</a>.</p>
              </div>
            </div>
          </nav>
        </div>
    )
  }
}

export default CookieBanner
