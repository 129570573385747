import React from 'react'
import { Link } from 'gatsby'
//import styles from './style.scss'
//import styles from './sass/style.scss'
import styles from './navigation.module.scss'

//className={[styles.navbar, styles.navbarFixedTop, styles.topNavCollapse].join(' ')}

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    }
  }
  showAll = () => {
       this.setState({
         open: !this.state.open
       });
     }
  render() {

    var navbarClass = [styles.navbar, styles.navbarFixedTop, styles.topNavCollapse].join(' ')

    var overlayClass = [styles.overlay, styles.overlayScale].join(' ')


    if (this.state.open) {
      navbarClass = [styles.navbar, styles.navbarFixedTop, styles.topNavCollapse, styles.overlayOpen].join(' ')
      overlayClass = [styles.overlay, styles.overlayScale, styles.open].join(' ')
    }
 

  return (<div>
  <nav id={"mainNav"} className={navbarClass}>
    <div className={[styles.containerFluid, styles.navContainer].join(' ')} >
      <div className={styles.navbarHeader}>
      <button onClick={this.showAll} id={"trigger-overlay"} className={styles.hamburger} aria-label={"Toggle YouView navigation"} type="button">
      <span className={styles.hamburgerBox}>
      <span className={styles.hamburgerInner}></span>
      </span>
      </button>
        <a className={[styles.navbarBrand, styles.support].join(' ')} href={process.env.MAIN_SITE_URL}><svg xmlns={"http://www.w3.org/2000/svg"} width="135" height="33" viewBox="0 0 135 33"><path d="M78.3 5.7v-5c0-.2.1-.3.3-.3h5.8c.2 0 .3.1.3.3v5c0 .2-.1.3-.3.3h-5.8c-.2 0-.3-.1-.3-.3zM40.1 12c.1.3.3.3.3.3s.2 0 .2-.2V8.4c0-.2.1-.3.3-.3h6c.2 0 .3.1.3.3v7.7c0 4.5 1.4 5.8 3.4 5.8s3.5-1.2 3.5-5.5v-8c0-.2.1-.3.3-.3h12l.3.2 2.4 9.4c.1.3.3.3.3.3l.3-.3a1382.7 1382.7 0 0 0 2.7-9.6h11.9c.2 0 .3.1.3.3v5.3c0 .1.1.3.3.3.1 0 .2 0 .3-.2 1.7-6.7 8.7-6.4 8.7-6.4 2.6 0 4.6.3 6.8 2.4l.2.1.2-.2-.4-1.2c0-.2.1-.3.3-.3h6.4l.3.2 2.4 9.4c0 .2.2.3.3.3.1 0 .3-.1.3-.3l1.9-7.3.5-2.1c0-.1.1-.2.3-.2h5.2c.2 0 .3.2.3.2a1230.5 1230.5 0 0 0 2.7 9.7c.3 0 .3-.4.4-.5l2.4-9.2.3-.2h10c.2 0 .3.1.3.3l-1.2 3.4c-.2.6-.9.7-.9.7h-2.1c-.4 0-.8.3-.9.6l-4.4 12.6-.3.2h-5.8l-.3-.2-2.8-8-.3-.3c-.1 0-.3.1-.3.3l-2.8 8-.3.2h-5.8l-.3-.2-2.1-6.1a1 1 0 0 0-.9-.7h-13c-.2 0-.3.2-.2.3.6 1.4 1.7 2.1 3.4 2.1h8.1c.4 0 .7.3.9.6l1.2 3.6c.1.2-.1.3-.3.3h-9.5c-3.1 0-4.8-.6-6.5-1.7-1.8-1.3-2.7-4.2-2.7-4.2-.1-.2-.3-.2-.3-.2s-.2 0-.2.2v5.6c0 .2-.1.3-.3.3h-5.8c-.2 0-.3-.1-.3-.3V12.8l-.2-.2-.2.2-4.5 12.9-.3.2h-7l-.3-.2L61 12.8l-.2-.2-.2.2V17a9 9 0 0 1-3.2 7.3c-1.7 1.4-3.6 2.1-7.1 2.1-3.2 0-4.9-.7-6.7-2.1-2.1-1.7-2.7-4-2.7-4l-.2-.1-.2.2s-.6 1.7-1.7 2.9c-.9.9-3.4 3.1-8.4 3.1a11 11 0 0 1-6.9-2.1c-3.5-2.8-3.3-7-3.3-7s-.1-.2-.3-.2l-.2.2S15.6 30 13.9 31.2a7 7 0 0 1-4.2 1H.9c-.2 0-.4-.2-.3-.4l2.3-3.9c.2-.3.5-.5.9-.5h4.7a1 1 0 0 0 1-1.2L3.4 8.5c0-.2.1-.3.3-.3h6.4l.3.2a1382.7 1382.7 0 0 0 2.7 9.7c.1 0 .3-.1.3-.3l2.4-9.4.3-.2h6.4c.4 0 .3.4.3.4l-.6 1.6.1.3s.2.1.3-.1c3.3-3.3 8-3 8-3s6.9-.5 9.5 4.6zm-5.4 4.6c0-5.4-3.9-5.1-3.9-5.1s-3.9-.4-3.9 5.1c0 5.9 3.8 5.3 3.8 5.3s4 .6 4-5.3zm63.1-1.2l-.2-1.1c-.5-1.9-1.7-2.8-3.5-2.8H94c-1.9 0-3.1.9-3.5 2.8l-.2 1.1.1.2.1.1h7.1l.2-.1v-.2z"></path></svg><span className={styles.logoText}>YouView Support</span></a>
      </div>
      <div id="navbar" className={[styles.navbarCollapse, styles.collapse, 'collapse'].join(' ')}>
        <ul id="menu-main-menu" className={[styles.nav, styles.navbarNav, styles.navbarRight].join(' ')}>
          <li><a title="Why YouView" href="https://www.youview.com/why-youview/">Why YouView</a></li>
          <li><a title="Get YouView" href="https://www.youview.com/get-youview/">Get YouView</a></li>
          <li className={styles.active}><a href={process.env.MAIN_SITE_URL}>Support</a></li>
          <li><a title="Careers" href="https://www.youview.com/careers/">Careers</a></li>
        </ul>
      </div>
    </div>
  </nav>
  <div id={"trigger-overlay-huge"} onClick={this.showAll} className={overlayClass}>
    <nav>
      <ul>
         <li><a title="Why YouView" href="https://www.youview.com/why-youview/">Why YouView</a></li>
          <li><a title="Get YouView" href="https://www.youview.com/get-youview/">Get YouView</a></li>
          <li className={styles.active}><a href={process.env.MAIN_SITE_URL}>Support</a></li>
          <li><a title="Careers" href="https://www.youview.com/careers/">Careers</a></li>
</ul>
</nav>
</div>
</div>
)
}
}
export default Navigation
