import React from 'react'
import { Link } from 'gatsby'

import Video from '../components/video'


import styles from './video-listing.module.scss'

function convertToSlug(Text)
{
    return Text
        .toLowerCase()
        .replace(/[^\w ]+/g,'')
        .replace(/ +/g,'-')
        .replace(/-+/g,'')
        ;
}

function getId(str){
  str = str.split(`/`)
  str = str.pop()
  if (str.indexOf(`?v=`) > -1){
    str = str.split(`?v=`)[1]
  }
  str = str.split(`?`)[0]
  str = str.split(`&`)[0]
  return str
}

function makeImgUrl(str){
  return ["https://img.youtube.com/vi/", str, "/mqdefault.jpg"].join('')
  }

  class VideoImageThumbnail extends React.Component {
    render() {
      const video = this.props.videoProps
      var link = ''

      if(video.node.videoPlaceholder) {
        link = video.node.videoPlaceholder
      }
      else {
        link = makeImgUrl(getId(video.node.videoLink))
      }
    return (
      <img src={link} />
      )
  }
}


class VideoListing extends React.Component {
  render() {
    const videos = this.props.videoNodes;

    const containerId = "videoContainer"

    var imgSrc

  var buttonContainerClass = styles.videoButton;
var buttonClass = ['collapsed ', ''].join('')


return (
  <div id={containerId} className={styles.videoContainer} role="tablist" aria-multiselectable="true">
  {videos.map((video) => (
    <div key={video.node.id} className={['panel', styles.panel].join(' ')}>
    <div id={['heading', convertToSlug(video.node.title)].join('')} className={buttonContainerClass}>
         <a className={buttonClass} data-toggle={'modal'} data-target={['#', convertToSlug(video.node.title)].join('')} aria-expanded="false" aria-controls={convertToSlug(video.node.title)} data-theVideo={['https://www.youtube.com/embed/', getId(video.node.videoLink)].join('')}>
          <span>{video.node.title}</span>
         <VideoImageThumbnail videoProps={video}/>
         </a>
         </div>
         </div>

    ))}


{videos.map((video) => (
<div key={video.node.id} className={"modal fade"} id={convertToSlug(video.node.title)} tabIndex={"-1"} role="dialog" aria-labelledby={['label', convertToSlug(video.node.title)].join('')}>
  <div class="modal-dialog modal-lg" role="document">
    <div class="modal-content video">
      <div class="modal-header video">
        <button type="button" class="close" data-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        <h4 class="modal-title sr-only" id={['label', convertToSlug(video.node.title)].join('')}>{video.node.title}</h4>
      </div>
      <div class="modal-body video">
        <Video id={video.node.videoLink}/>
      </div>
    </div>
  </div>
</div>
))}
</div>
)
  }
}

export default VideoListing
