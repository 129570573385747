import React from 'react'
import { Link } from 'gatsby'
//import styles from './navigation.module.css'

import styles from './footer.module.scss'


export default () => (
   <div className={styles.footerNavigation}>
              
    <div className={['panel-group', styles.panelGroup].join(' ')}  id="footer-accordion" role="tablist" aria-multiselectable="true">
      <div className={['panel panel-default menu about', styles.menu].join(' ')}>
        <div class="panel-heading" role="tab" id="footer-heading-about-us">
          <h4 class="panel-title">
            <a class="collapsed" role="button" data-toggle="collapse" data-parent="#footer-accordion" href="#about-us" aria-expanded="false" aria-controls="about-us">About YouView</a>
          </h4>
        </div>
        <div id="about-us" class="panel-collapse collapse" role="tabpanel" aria-labelledby="footer-heading-about-us">
          <div class="panel-body ">
            <div class="menu-about-youview-container">
              <ul id="menu-about-youview" className={styles.menu}>
                <li><a href="https://www.youview.com/about-us/">About Us</a></li>
                <li><a href="https://www.youview.com/press-and-news/">Press and News</a></li>
                <li><a href="https://www.youview.com/contact-us/">Contact Us</a></li>
                <li><a href="https://www.youview.com/frequently-asked-questions/">FAQs</a></li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div class="panel panel-default menu legal">
        <div class="panel-heading" role="tab" id="footer-heading-legal">
          <h4 class="panel-title"><a class="collapsed" role="button" data-toggle="collapse" data-parent="#footer-accordion" href="#legal" aria-expanded="false" aria-controls="legal">Legal Menu</a></h4></div>
          <div id="legal" class="panel-collapse collapse" role="tabpanel" aria-labelledby="footer-heading-legal">
            <div class="panel-body ">
              <div class="menu-legal-container">
                <ul id="menu-legal" class="menu">
                  <li><a href="https://www.youview.com/terms-conditions/">Terms and Conditions</a></li>
                  <li><a href="https://www.youview.com/policies/">Policies</a></li>
                </ul>
              </div>
            </div>
          </div>
        </div> 
        <div class="panel panel-default menu industry">
          <div class="panel-heading" role="tab" id="footer-heading-industry-zone">
            <h4 class="panel-title">
              <a class="collapsed" role="button" data-toggle="collapse" data-parent="#footer-accordion" href="#industry-zone" aria-expanded="false" aria-controls="industry-zone">Industry Zone</a>
            </h4>
          </div>
          <div id="industry-zone" class="panel-collapse collapse" role="tabpanel" aria-labelledby="footer-heading-industry-zone">
            <div class="panel-body ">
              <div class="menu-industry-zone-container">
                <ul id="menu-industry-zone" class="menu">
                  <li><a href="https://www.youview.com/open-source/">Open Source</a></li>
                  
                </ul>
              </div>
            </div>
          </div>
        </div> 

</div>

</div>
)
