import React from 'react'
import { Link } from 'gatsby'

//import styles from './article-preview.module.css'
import styles from './alert.module.scss'

import { BLOCKS, MARKS, INLINES } from '@contentful/rich-text-types';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';


const Bold = ({ children }) => <strong>{children}</strong>;
const Text = ({ children }) => <p>{children}</p>;

class EntryHyperlink extends React.Component {
  render() {
    const fields = this.props.fields
    const content = this.props.content
    
    var pageSlug = '';
    var linkTitle = ''

    if(this.props.content) {
      linkTitle = this.props.content.value
    }
    else {
      linkTitle = fields.title[`en-US`]
    }
    if(this.props.fields) {
      if(fields.productReference) {
        pageSlug += `/${fields.productReference['en-US'].fields.slug['en-US']}`;
      }

      if(fields.articleCategoryTopic) {
        pageSlug += `/${fields.articleCategoryTopic['en-US'].fields.slug['en-US']}`;
      }
      if(fields.pageTopic) {
        pageSlug += `/${fields.pageTopic['en-US'].fields.slug['en-US']}`;
      }
      
      if(fields.slug) {
        pageSlug += `/${fields.slug['en-US']}/`;
      }
    }
    var linkReact = <Link to={pageSlug}>{linkTitle}</Link>

    return ( linkReact
      
      )
  }
}

const options = {
  renderMark: {
    [MARKS.BOLD]: text => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
    [INLINES.ENTRY_HYPERLINK]: (node) => {
       const fields = node.data.target.fields;
        const content = node.content[0];
        if(node.data.target.sys.contentType != null) {
          //console.log(node.data.target.sys.contentType)  
        }
        else {
          //console.log("no contentType!")
        }
        var nodeType = ''
        
        if (typeof(node.data.target.sys.contentType) !== 'undefined') {
          nodeType = node.data.target.sys.contentType.sys.id
        }
         // if(node.data.target.sys.contentType.sys.id) {
         //  nodeType = node.data.target.sys.contentType.sys.id
         // }
         // console.log(content)
        //return fields.id
        if(nodeType == 'supportSiteArticle' || nodeType == 'supportSitePage') {
          return <EntryHyperlink content={content} fields={fields}/>
        }
        else {
          return null
        }
    }

 },
 renderText: text => {
   return text.split('\n').reduce((children, textSegment, index) => {
     return [...children, index > 0 && <br key={index} />, textSegment];
   }, []);
 },
};


class Alert extends React.Component {
  constructor(props) {
    super(props)
  }
  render() {
    if (!this.props.alertIndex || this.props.alertIndex.totalCount == 1) {
       return null;
     }



     var carouselId = "alertCarousel"

     var alertMessage = ''

     var totalAlerts = this.props.alertIndex.totalCount - 1;
     if(totalAlerts > 1) {
      alertMessage = totalAlerts + " Service Alerts"
     }
     else {
      alertMessage = totalAlerts + " Service Alert"
     }

     const allAlerts = this.props.alertIndex.edges

     var carouselControls = '';
     var slideIndicators = '';

     let indicators = [];

     if (totalAlerts > 1) {
      carouselControls = <div className={"carousel-controls"}><a className={[styles.carouselControl, 'left carousel-control'].join(' ')} href={"#"+ carouselId } role="button" data-slide="prev">
          <i className={"icon-chevrons-left"} aria-hidden={"true"}></i>
          <span className={"sr-only"}>Previous</span>
        </a>
        <a className={[styles.carouselControl, 'right carousel-control'].join(' ')} href={"#"+ carouselId } role="button" data-slide="next">
          <i className={"icon-chevrons-right"} aria-hidden={"true"}></i>
          <span className={"sr-only"}>Next</span>
        </a>
        </div>

        for (var i = 0; i < totalAlerts; i++) {
          var itemClass = ''
          if(i == 0){itemClass = "active"}
            else {itemClass = false}
                indicators.push(<li data-target={"#" + carouselId} data-slide-to={i} className={itemClass}></li>);
            }

        slideIndicators = <ol className={"carousel-indicators"}>{indicators}</ol>
     }
     var firstElement = true

      return (
        <section className={styles.alertSection}>
              <div className={styles.panelGroup} role="tablist" aria-multiselectable="true">
                <div className={[styles.panelDefault, 'panel-default panel'].join(' ')}>
                  <div className={styles.panelHeading} role="tab" id="alertHeading">
                    <h4 className={styles.panelTitle}>
                      <a className={[styles.collapsed, 'collapsed'].join(' ')}role="button" data-toggle="collapse" data-parent="#alertHeading" href="#alertStatus" aria-expanded="false" aria-controls="alertStatus">
                        <i className={"icon-alert-triangle"}></i> {alertMessage}
                      </a>
                    </h4>
                  </div>
                  <div id="alertStatus" className={[styles.panelCollapse, 'panel-collapse collapse'].join(' ')} role="tabpanel" aria-labelledby="alertHeading">
                    <div className={styles.panelBody}>

                    

                    <div id={carouselId} className={"carousel slide"} data-ride="carousel">
                    {slideIndicators}
            <div className={"carousel-inner"} role="listbox">


              {
                allAlerts.map((alert, i) => {
              if(alert.node.title != 'dummy'){
                var itemClass = "item"
                if (firstElement == true) {
                  itemClass = "item active"
                  firstElement = "false"

                }
              
              return (
              
                <div key={alert.node.id} className={itemClass}>
                
                <h5><strong>{alert.node.title}</strong></h5>
                {documentToReactComponents(alert.node.body.json, options)}
                </div>
                )

              }
              }
            )}
            </div>
            {carouselControls}

</div>

                     
                   </div>
                 </div>
               </div>
             </div>
          
         </section>
  )
}
}

export default Alert
